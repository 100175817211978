import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getWorkOrderOpTask, getWorkOrderOpTasks } from '../api';
import SubTasksList from '../components/SubTasksList';
import SubTaskPopup from '../components/SubTaskPopup';

const SubTasksWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function SubTasks(props) {
	const { workOrderOpId, onTasksChange } = props;
	const [loading, setLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [selectedSubTask, setSelectedSubTask] = useState({});
	const [err, setErr] = useState(false);
	const [tasks, setTasks] = useState([]);

	async function fetchTasks() {
		setLoading(true);
		try {
			const data = await getWorkOrderOpTasks(workOrderOpId);
			setTasks(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	function updateSubTasks(newTask) {
		const newSubtasks = tasks.map((task) => {
			if (task.id === newTask.id) {
				return {
					...task,
					...newTask
				};
			}

			return task;
		});

		setTasks(newSubtasks);
		const newSelectedSubTask = newSubtasks.find(st => st.id === selectedSubTask.id);

		setSelectedSubTask(newSelectedSubTask);

		onTasksChange();
	}

	async function fetchSubTask(id) {
		try {
			const data = await getWorkOrderOpTask(workOrderOpId, id);
			updateSubTasks(data);
		} catch (e) {
			setErr(true);
		}
	}

	useEffect(() => {
		if (workOrderOpId) {
			fetchTasks();
		}
	}, [workOrderOpId]);

	function onSubTaskSelect(subTask) {
		setShowPopup(true);
		setSelectedSubTask(subTask);
	}

	return (
		<SubTasksWrapper>
			<ListWrapper>
				<SubTasksList onClick={onSubTaskSelect} loading={loading} subTasks={tasks} />
			</ListWrapper>
			<SubTaskPopup
				visible={showPopup}
				onChange={fetchSubTask}
				subTask={selectedSubTask}
				onClose={() => setShowPopup(false)}
			/>
		</SubTasksWrapper>
	);
}

export default SubTasks;
