import React, { useContext, Fragment, useState } from 'react';
import { List, SpinLoading, ErrorBlock } from 'antd-mobile';
import get from 'lodash/get';
import moment from 'moment';
import { DownlandOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';

const Notes = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function NotesList(props) {
	const {
		notes,
		onClick,
		disabled,
		relatedObjectType,
		loading
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	function getNotesList() {
		if (!notes.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description=""
				title={'No notes found'}
				status='empty'
			/>;
		}

		return <Notes>
			{notes.map((note) => {
				return <List.Item
					key={note.id}
					title={note.user.name}
					onClick={() => (disabled ? null : onClick(note))}
					description={moment(note.created_at).format(get(userSelf.settings, 'date_format'))}
					clickable={disabled ? false : note.object_type !== relatedObjectType}
				>
					{note.content || '-'}
				</List.Item>;
			})}
		</Notes>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getNotesList()}
		</Fragment>
	);
}

export default NotesList;
