import React, { useState, useContext, Fragment } from 'react';
import { List, Button } from 'antd-mobile';
import { CheckOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import { store } from '../utils/store';
import { SIGNATURE_TYPE_TASK } from '../utils/constants';
import { sign, unsign } from '../api';
import { matchesPermission } from '../utils/permission-helpers';
import { getSignatureTypes, getUserAllowedSignatureTypes } from '../utils/helpers';
import AddSignaturePopup from './AddSignaturePopup';

const EmptyText = styled.span`
	color: var(--adm-color-weak);
`;

const ButtonWrapper = styled.div`
	padding: 8px;
	margin-top: 16px;
`;

const Required = styled.span`
	display: inline-block;
	margin-left: 2px;
	color: #f5222d;
	font-size: 14px;
	line-height: 1;
`;


function Signatures(props) {
	const {
		item,
		objectType,
		onChange,
		isDisabled
	} = props;
	const globalState = useContext(store).state;
	const [saving, setSaving] = useState('');
	const { userSelf } = globalState;
	const [showPopover, setShowPopover] = useState(false);
	const [isRemove, setIsRemove] = useState(false);
	const [selectedSignature, setSelectedSignature] = useState({});

	const disabled = isDisabled || !matchesPermission(userSelf, 'add_signatures_work_orders');

	const SIGNATURE_TYPES = getSignatureTypes(userSelf, SIGNATURE_TYPE_TASK);

	const userAllowedSignatures = getUserAllowedSignatureTypes(userSelf, SIGNATURE_TYPE_TASK, false);

	const allowedSignatures = userAllowedSignatures
		.map(st => st.id)
		.filter(id => !(item.signatures || []).find(s => s.signature_type_id === id && s.user_id === userSelf.id));

	async function signSelf(signatureId) {
		setSaving(signatureId);
		try {
			await sign({
				object_type: objectType,
				signature_type_id: signatureId,
				object_id: item.id
			});
			await onChange(item.id);
			setShowPopover(false);
			setSaving('');
		} catch (err) {
			setSaving('');
		}
	}

	async function unsignSelf(signatureId) {
		setSaving(true);
		try {
			await unsign({ id: signatureId });

			await onChange(item.id, true);
			setShowPopover(false);
			setSaving('');
		} catch (err) {
			setSaving(false);
		}
	}

	function getSignaturesByType(typeId) {
		return item.signatures.filter(s => s.signature_type_id === typeId) || [];
	}

	function isRequired(typeId) {
		return item.required_signature_ids ? item.required_signature_ids.includes(typeId) : false;
	}

	function getGroupName(id) {
		const type = SIGNATURE_TYPES.find(t => t.id === id);

		return type ? type.name : 'Unknown';
	}

	function hasSignature(typeId) {
		return getSignaturesByType(typeId).some(s => s.user_id === userSelf.id);
	}

	function onSignatureClick(typeId) {
		const userSignature = getSignaturesByType(typeId).find(s => s.user_id === userSelf.id);
		if (userSignature) {
			setSelectedSignature(userSignature);
			setIsRemove(true);
			setShowPopover(true);
		}
	}

	const signatureTypes = uniq([...(item.required_signature_ids || []), ...(item.signatures || []).map(s => s.signature_type_id)]);

	function onMarkAsComplete() {
		if (userAllowedSignatures.length > 1) {
			setIsRemove(false);
			setShowPopover(true);
		} else {
			signSelf(userAllowedSignatures[0].id);
		}
	}

	return (<div>
		<List header="Completed by">
			{signatureTypes.map((typeId) => {
				return <List.Item
					key={typeId}
					title={<Fragment>
						{getGroupName(typeId)}
						{isRequired(typeId) ? <Required>*</Required> : null}
					</Fragment>}
					clickable={hasSignature(typeId) && !disabled}
					onClick={() => hasSignature(typeId) && !disabled && onSignatureClick(typeId)}
				>	{!getSignaturesByType(typeId).length && <EmptyText>Not completed yet...</EmptyText>}
					{getSignaturesByType(typeId).map(s => s.signature_data.name).join(', ')}
				</List.Item>;
			})}
			{!signatureTypes.length && <List.Item><EmptyText>Not completed yet...</EmptyText></List.Item>}
		</List>
		{!!allowedSignatures.length && !disabled && !!userAllowedSignatures.length && <ButtonWrapper>
			<Button loading={saving} onClick={onMarkAsComplete} color={'success'} block>
				<CheckOutline /> Mark as Completed
			</Button>
		</ButtonWrapper>}
		<AddSignaturePopup
			visible={showPopover}
			signatureId={selectedSignature.id}
			userAllowedSignatures={userAllowedSignatures}
			allowedSignatures={allowedSignatures}
			signatureName={selectedSignature.signature_name}
			isRemove={isRemove}
			saving={saving}
			onSelect={signSelf}
			onDelete={unsignSelf}
			onClose={() => {
				setShowPopover(false);
				setSelectedSignature({});
				setIsRemove(false);
			}}
		/>
	</div>);
}

export default Signatures;
