import React, { useContext, Fragment, useState } from 'react';
import { List, SpinLoading, ErrorBlock, Ellipsis } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import AddToolPopup from './AddToolPopup';
import { matchesPermission } from '../utils/permission-helpers';

const Tools = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function ToolsList(props) {
	const {
		tools,
		loading,
		isAdd,
		onAdd,
		onDelete,
		saving
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [showPopover, setShowPopover] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});


	function getUsedIn(material) {
		if (material.work_order_op_id) {
			return material.work_order_op_title || '-';
		}

		if (material.defect_id) {
			return material.defect_title || '-';
		}

		return null;
	}

	async function onToolSave(data) {
		await onAdd(data);

		setShowPopover(false);
	}

	async function onToolRemove(id) {
		await onDelete(id);

		setShowPopover(false);
	}

	function getDescription(tool) {
		if (isAdd) {
			return `${tool.barcode ? `BC: ${tool.barcode}` : ''}`;
		}

		return getUsedIn(tool) ? <Ellipsis direction='end' rows={1} content={`Used in: ${getUsedIn(tool)}`} /> : '';
	}

	function getToolsList() {
		if (!tools.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description={isAdd ? 'Try another search term' : ''}
				title={isAdd ? 'No tools found' : 'No tools added'}
				status='empty'
			/>;
		}

		return <Tools>
			{tools.map((tool) => {
				return <List.Item
					key={tool.id}
					title={`${tool.marking ? `${tool.marking}` : ''} ${tool.part_nr ? `/ ${tool.part_nr}` : ''} ${tool.serial ? `/ ${tool.serial}` : ''}`}
					description={getDescription(tool)}
					onClick={!matchesPermission(userSelf, 'delete_work_order_tools') ? null : () => {
						if (matchesPermission(userSelf, 'delete_work_order_tools')) {
							setSelectedItem(tool);
							setShowPopover(true);
						}
					}}
					clickable={matchesPermission(userSelf, 'delete_work_order_tools')}
				>
					{tool.name || '-'}
				</List.Item>;
			})}
		</Tools>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getToolsList()}
			<AddToolPopup
				visible={showPopover}
				selectedItem={selectedItem}
				isAdd={isAdd}
				initialValue={selectedItem.amount || 1}
				loading={saving}
				onSave={onToolSave}
				onDelete={onToolRemove}
				onClose={() => setShowPopover(false)}
			/>
		</Fragment>
	);
}

export default ToolsList;
