import React, { useState, useContext, useEffect } from 'react';
import { NavBar, Tabs, List, Ellipsis, Skeleton, ErrorBlock, Badge } from 'antd-mobile';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { getDefect, updateWorkOrderDefect } from '../api';
import { store } from '../utils/store';
import Signatures from '../components/Signatures';
import InputComponent from '../components/Input';
import Materials from './Materials';
import Files from './Files';
import Notes from './Notes';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import Tools from './Tools';

const DefectWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const DefectInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	.adm-list-item-title {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Description = styled(List.Item)`
	font-size: var(--adm-font-size-9);
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow: auto;
		padding: 0;
	}
`;

function Defect() {
	const params = useParams();
	const history = useHistory();
	const { id, workOrderId } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [defect, setDefect] = useState({});

	async function fetchDefect(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getDefect(workOrderId, id);
			setDefect(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchDefect();
	}, []);

	async function updateDefect(data) {
		const payload = data;
		payload.id = defect.id;

		try {
			const respData = await updateWorkOrderDefect(workOrderId, data);
			setDefect(respData);
		} catch (e) {
			setErr(true);
		}
	}


	return (err ? <NotFound
		title="Additional work not found"
		description="This additional work does not exist, or you dont have rights to view it"
		status='empty'
	/> : <DefectWrapper>
		<Nav onBack={() => history.push(`/work-order/${defect.work_order_id}`)} back={''}>
			Additional work
		</Nav>
		<DefectInfo>
			{loading ? <Skeleton.Paragraph lineCount={2} animated/> : <Description><Ellipsis
				direction='end'
				rows={4} content={defect.description || '-'}
				expandText="Show"
				collapseText="Hide"
			/></Description>}
		</DefectInfo>
		<ContentWrapper>
			<TabBar>
				<Tabs.Tab
					title={'Overview'}
					key='overview'
				>
					<Overview>
						<InputComponent
							placeholder="Insert rectification method"
							onSave={updateDefect}
							disabled={!matchesPermission(userSelf, 'edit_defects')}
							dataKey="restification_method"
							isRequired={true}
							initialValue={defect.restification_method}
							title="Rectification method"
							type="text"
						/>
						<InputComponent
							placeholder="Insert reference"
							onSave={updateDefect}
							disabled={!matchesPermission(userSelf, 'edit_defects')}
							dataKey="reference"
							initialValue={defect.reference}
							title="Reference"
							type="text"
						/>
						<InputComponent
							placeholder="Type comment"
							disabled={!matchesPermission(userSelf, 'edit_defects')}
							onSave={updateDefect}
							dataKey="comment"
							initialValue={defect.comment}
							title="Comment"
							type="text"
						/>
						<InputComponent
							onSave={updateDefect}
							disabled={!matchesPermission(userSelf, 'edit_defects')}
							dataKey="done_date"
							isRequired={true}
							title="Done date"
							type="date"
							initialValue={defect.done_date}
						/>
						{featureEnabled(userSelf, 'work_order_staff_enabled') && <Signatures
							onChange={async (id, removed) => {
								if (!removed) {
									await updateDefect({
										done_date: moment()
									});
								}

								fetchDefect(true);
							}}
							item={defect}
							objectType={'defect'}
						/>}
					</Overview>
				</Tabs.Tab>
				{featureEnabled(userSelf, 'warehouse_enabled') && matchesPermission(userSelf, 'view_work_order_materials') &&
					<Tabs.Tab title='Materials' key='materials'>
						<Materials defectId={defect.id} workOrderId={defect.work_order_id} />
					</Tabs.Tab>
				}
				{featureEnabled(userSelf, 'tools_enabled') && matchesPermission(userSelf, 'view_work_order_tools') &&
					<Tabs.Tab title='Tools' key='tools'>
						<Tools defectId={defect.id} workOrderId={defect.work_order_id} />
					</Tabs.Tab>
				}
				<Tabs.Tab
					title={<Badge
						content={defect.files_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Files
					</Badge>}
					key='files'
				>
					<Files
						onChange={() => fetchDefect(true)}
						objectType="defect"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_defects')}
						objectId={defect.id}
					/>
				</Tabs.Tab>
				<Tabs.Tab
					title={<Badge
						content={defect.notes_count || null}
						style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
							Notes
					</Badge>}
					key='notes'
				>
					<Notes
						onChange={() => fetchDefect(true)}
						objectType="defect"
						disabled={!matchesPermission(userSelf, 'manage_files_notes_defects')}
						objectId={defect.id}
					/>
				</Tabs.Tab>
			</TabBar>
		</ContentWrapper>
	</DefectWrapper>);
}

export default Defect;
