import React, { useContext } from 'react';
import { Popup, Button, List, CheckList, DotLoading } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';

const PopupContent = styled(List)`
	padding: 12px;
	margin-top: 12px;

	.adm-list-body {
		border-top: none;
	}
`;

const SignatureList = styled(List)`
	.adm-list-body {
		border-bottom: none;
	}
`;


function AddSignaturePopup(props) {
	const {
		signatureId,
		userAllowedSignatures,
		allowedSignatures,
		isRemove,
		visible,
		onClose,
		onSelect,
		onDelete,
		saving
	} = props;
	const globalState = useContext(store);
	const { userSelf } = globalState;

	return (
		<Popup
			visible={visible}
			showCloseButton={true}
			onClose={onClose}
			destroyOnClose={true}
			onMaskClick={onClose}
			bodyStyle={{
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
				minHeight: 100
			}}
		>
			<PopupContent>
				{!isRemove && <SignatureList header="Complete as">
					<CheckList activeIcon={<DotLoading />} onChange={val => onSelect(val[0])}>
						{userAllowedSignatures.map((signature) => {
							return <CheckList.Item
								key={signature.id}
								extra={saving === signature.id ? <DotLoading /> : null}
								disabled={!allowedSignatures.includes(signature.id)}
								value={signature.id}
							>
								{signature.name}
							</CheckList.Item>;
						})}
					</CheckList>
				</SignatureList>}
				{isRemove ? <Button
					style={{ marginTop: 24 }}
					onClick={() => onDelete(signatureId)}
					color="danger"
					loading={saving}
					block
				>
					Remove signature
				</Button> : null}
			</PopupContent>
		</Popup>
	);
}

export default AddSignaturePopup;
