import 'antd-mobile/es/global';

import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ErrorBlock } from 'antd-mobile';
import get from 'lodash/get';
import styled from 'styled-components';
import { getUserSelf } from '../api';
import { store, SET_USER_SELF } from '../utils/store';
import Login from './Login';
import Navigation from './Navigation';
import Task from './Task';
import WorkOrders from './WorkOrders';
import WorkOrder from './WorkOrder';
import Defect from './Defect';
import Profile from './Profile';
import { matchesPermission } from '../utils/permission-helpers';

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};

const MainContent = styled.div`
	height: var(--app-height);
	--adm-color-primary: #376BFB;
	display: flex;
	flex-flow: column;
	background: #f0f2f5;
  	flex-direction: column;
`;

const Body = styled.div`
	flex: 1;
	overflow: auto;
	justify-content: center;
	align-items: center;
`;

function App() {
	const [userSelf, setUserSelf] = useState({});
	const [loading, setloading] = useState(true);
	const [err, setErr] = useState(false);
	const globalState = useContext(store);
	const { dispatch } = globalState;

	async function fetchUserSelf() {
		setloading(true);

		try {
			const selfData = await getUserSelf();
			setUserSelf(selfData);
			dispatch({ type: SET_USER_SELF, userSelf: selfData });
		} catch (e) {
			if (get(e.response, 'status') !== 403) {
				setErr(true);
			}
		}

		setloading(false);
	}

	useEffect(() => {
		fetchUserSelf();

		window.addEventListener('resize', appHeight);
		appHeight();
	}, []);

	function accessDenied() {
		return <ErrorBlock
			title="Access denied"
			description="You dont have permissions to view this page"
			status="empty"
		/>;
	}

	function redirectToLogin() {
		window.location.href = `${process.env.API_URL}/login?redirect=${process.env.APP_URL}`;
	}

	function PrivateRoute({ component: Component, authed, ...rest }) {
		return (
			<Route
				{...rest}
				render={props => (authed
					? <Component {...props} />
					: redirectToLogin()
				)}
			/>
		);
	}

	function getRoutes() {
		if (err) {
			return <ErrorBlock title="Oops, something wrong" description={'Please try to refresh page or try again later'} fullPage status="disconnected" />;
		}

		return <Router>
			<MainContent>
				<Body>
					<Switch>
						<Route exact path="/login">
							<Login />
						</Route>
						<PrivateRoute
							exact
							path='/'
							authed={(!!userSelf.id && !loading)}
							component={() => (matchesPermission(userSelf, 'view_work_orders') ? <WorkOrders /> : accessDenied())}
						/>
						<PrivateRoute
							exact
							path='/work-order/:id'
							authed={(!!userSelf.id && !loading)}
							component={() => (matchesPermission(userSelf, 'view_work_orders') ? <WorkOrder /> : accessDenied())}
						/>
						<PrivateRoute
							exact
							path='/work-order/:workOrderId/task/:id'
							authed={(!!userSelf.id && !loading)}
							component={() => (matchesPermission(userSelf, 'view_work_orders') ? <Task /> : accessDenied())}
						/>
						<PrivateRoute
							exact
							path='/work-order/:workOrderId/defect/:id'
							authed={(!!userSelf.id && !loading)}
							component={() => (matchesPermission(userSelf, 'view_work_orders') ? <Defect /> : accessDenied())}
						/>
						<PrivateRoute exact path='/profile' authed={(!!userSelf.id && !loading)} component={() => <Profile />} />
						<Route
							render={() => (
								<ErrorBlock status='empty' description={null} title="Sorry, this page dos not exist" />
							)}
						/>
					</Switch>
				</Body>
				{!!userSelf.id && !loading && <Navigation />}
			</MainContent>
		</Router>;
	}

	return (loading ? <ErrorBlock title="Loading..." description={null} fullPage status='busy' /> : getRoutes());
}

export default App;
