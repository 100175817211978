import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar, Tabs, List, Ellipsis, Badge, Skeleton, ErrorBlock } from 'antd-mobile';
import get from 'lodash/get';
import styled from 'styled-components';
import { getWorkOrderOperation, getWorkOrderOperationStats, updateWorkOrderOperation } from '../api';
import { store } from '../utils/store';
import SubTasks from './SubTasks';
import Signatures from '../components/Signatures';
import InputComponent from '../components/Input';
import Materials from './Materials';
import Files from './Files';
import Notes from './Notes';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import Tools from './Tools';

const TaskWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const TaskInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	.adm-list-item-title {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow:  auto;
		padding: 0;
	}
`;

function Task() {
	const params = useParams();
	const history = useHistory();
	const { id, workOrderId } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [task, setTask] = useState({});
	const [stats, setStats] = useState({});

	async function fetchTask(silent) {
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getWorkOrderOperation(workOrderId, id);
			setTask(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	async function fetchTaskStats() {
		setLoading(true);
		try {
			const data = await getWorkOrderOperationStats(workOrderId, id);
			setStats(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchTask();
		fetchTaskStats();
	}, []);

	function getRemainingTasks() {
		return Number(get(stats, 'total_tasks') - get(stats, 'done_tasks')) || 0;
	}

	async function updateTask(data) {
		const payload = data;
		payload.id = task.id;

		const resp = await updateWorkOrderOperation(workOrderId, data);

		setTask(resp);
	}

	return (
		err ? <NotFound
			title="Task not found"
			description="This task does not exist, or you dont have rights to view it"
			status='empty'
		/> : <TaskWrapper>
			<Nav onBack={() => history.push(`/work-order/${task.work_order_id}`)} back={''}>
				Task
			</Nav>
			<TaskInfo>
				{loading ?
					<Fragment>
						<Skeleton.Title animated/>
						<Skeleton.Paragraph lineCount={1} animated/>
					</Fragment>
					:
					<List.Item
						title={<Fragment>
							<h2>{task.name || '-'}</h2>
						</Fragment>}
						description={<Ellipsis
							direction='end'
							rows={3} content={task.description || '-'}
							expandText="Show"
							collapseText="Hide"
						/>}
					/>}
			</TaskInfo>
			<ContentWrapper>
				{task.id && <TabBar defaultActiveKey={task.has_tasks ? 'subtasks' : 'overview'}>
					<Tabs.Tab
						title={'Overview'}
						key='overview'
					>
						<Overview>
							<InputComponent
								placeholder="Type comment"
								onSave={updateTask}
								dataKey="comment"
								disabled={!matchesPermission(userSelf, 'edit_work_order_operations')}
								initialValue={task.comment}
								title="Comment"
								type="text"
							/>
							<InputComponent
								onSave={updateTask}
								dataKey="done_date"
								disabled={!matchesPermission(userSelf, 'edit_work_order_operations')}
								title="Done date"
								isRequired={true}
								type="date"
								initialValue={task.done_date}
							/>
							{featureEnabled(userSelf, 'work_order_staff_enabled') && <Signatures
								onChange={() => fetchTask(true)}
								item={task}
								isDisabled={task.has_tasks}
								objectType={'operation'}
							/>}
						</Overview>
					</Tabs.Tab>
					{task.has_tasks && <Tabs.Tab
						title={<Badge content={getRemainingTasks() || null} style={{ '--right': '-10px', '--top': '4px' }}>Subtasks</Badge>}
						key='subtasks'
					>
						<SubTasks
							onTasksChange={() => {
								fetchTaskStats();
								fetchTask();
							}}
							workOrderOpId={task.id}
						/>
					</Tabs.Tab>}
					{featureEnabled(userSelf, 'warehouse_enabled') && matchesPermission(userSelf, 'view_work_order_materials') &&
						<Tabs.Tab title='Materials' key='materials'>
							<Materials
								workOrderOpId={task.id}
								workOrderId={task.work_order_id}
							/>
						</Tabs.Tab>
					}
					{featureEnabled(userSelf, 'tools_enabled') && matchesPermission(userSelf, 'view_work_order_tools') &&
						<Tabs.Tab title='Tools' key='tools'>
							<Tools
								workOrderOpId={task.id}
								workOrderId={task.work_order_id}
							/>
						</Tabs.Tab>
					}
					<Tabs.Tab title='Files' key='files'>
						<Files
							objectType="work_order_op"
							objectId={task.id}
							disabled={!matchesPermission(userSelf, 'manage_files_work_order_operations')}
							relatedObjectType="operation"
							relatedObjectId={task.operation_id}
						/>
					</Tabs.Tab>
					<Tabs.Tab title='Notes' key='notes'>
						<Notes
							objectType="work_order_op"
							objectId={task.id}
							disabled={!matchesPermission(userSelf, 'manage_files_work_order_operations')}
							relatedObjectType="operation"
							relatedObjectId={task.operation_id}
						/>
					</Tabs.Tab>
				</TabBar>}
			</ContentWrapper>
		</TaskWrapper>
	);
}

export default Task;
