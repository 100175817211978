import get from 'lodash/get';

export const matchesPermission = (userSelf, key) => {
	if (userSelf.is_superadmin) {
		return true;
	}

	const permissionValues = get(userSelf.permission_group, 'values');

	if (!permissionValues) {
		return false;
	}

	const isEnabled = permissionValues[key];

	return isEnabled;
};


export const featureEnabled = (userSelf, key) => {
	return !!get(userSelf.active_organization.features, key);
};
