import React, { useState, useContext, useEffect } from 'react';
import { Input, Button, Form, SafeArea, Toast } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { login } from '../api';
import { store } from '../utils/store';

import logo from '../assets/images/wingops-logo-blue.svg';

const LoginBackground = styled.div`
	display: flex;
	height: 100%;
	background: #fff;
`;

const LoginWrapper = styled.div`
	display: flex;
	background: #fff;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	overflow: hidden;
`;

const LoginButton = styled(Button)`
	width: 100%;
`;

const LoginForm = styled(Form)`
	display: flex;
	padding: 12px;
	width: calc(100% - 48px);
	padding: 24px;

	.adm-list {
		width: 100%;
	}
`;

const Logo = styled.img`
	width: 200px;
	margin-bottom: 36px;
`;

const Login = () => {
	const [loading, setLoading] = useState(false);
	const globalState = useContext(store).state;
	const history = useHistory();
	const { userSelf } = globalState;

	const onFinish = async (values) => {
		setLoading(true);
		try {
			await login(values);
			window.location.href = '/';
		} catch (err) {
			Toast.show({
				icon: 'fail',
				content: 'Wrong email or password'
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		if (userSelf.id) {
			history.push('/');
		}
	}, [userSelf.id]);

	return (
		<LoginBackground>
			<LoginWrapper>
				<SafeArea position='top' />
				<Logo src={logo} />
				<LoginForm name="login" layout="vertical" onFinish={onFinish}>
					<Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please insert Email!' }]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: 'Please insert password!' }]}
					>
						<Input type={'password'} />
					</Form.Item>
					<Form.Item>
						<LoginButton loading={loading} color='primary' fill='solid' type="submit">
							Log in
						</LoginButton>
					</Form.Item>
				</LoginForm>
				<SafeArea position='bottom' />
			</LoginWrapper>
		</LoginBackground>
	);
};

export default Login;
