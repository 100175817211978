/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { List, Ellipsis, SpinLoading, ErrorBlock } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import { store } from '../utils/store';
import { featureEnabled } from '../utils/permission-helpers';

const Tasks = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function SubTasksList(props) {
	const { subTasks, loading, onClick } = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	function getExra(task) {
		if (task.is_info) {
			return null;
		}

		if (task.is_input) {
			return task.input_value;
		}

		const signatureTypeIds = uniq((task.signatures || []).map(s => s.signature_type_id));

		const hasDoneDate = !!task.done_date;
		const isSigned = featureEnabled(userSelf, 'work_order_staff_enabled') ?
			(task.required_signature_ids.length ? !task.required_signature_ids.some(id => !signatureTypeIds.includes(id)) : true)
			: true;

		return hasDoneDate && isSigned && <CheckCircleFill fontSize={24} color="var(--adm-color-success)" />;
	}

	function getList() {
		if (!subTasks.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title="No subtasks found"
				description=""
				status='empty'
			/>;
		}

		return <Tasks>
			{subTasks.map((task) => {
				return <List.Item
					key={task.id}
					description={task.is_info ? task.description || '-' : ''}
					onClick={() => !task.is_info && onClick(task)}
					extra={getExra(task)}
					clickable={!task.is_info}
				>
					{!task.is_info ? <Ellipsis direction='end' rows={3} content={task.description || '-'} /> : ''}
				</List.Item>;
			})}
		</Tasks>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : getList()
	);
}

export default SubTasksList;
