import React, { useContext, Fragment } from 'react';
import { Image, List, Button } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import { logout } from '../api';

const ProfileDefails = styled(List)`
	padding: 24px 12px;
	border-top: none;
	background: #fff;
	.adm-list-body {
		border-top: none;
		border-bottom: none;
	}
`;

const Logout = styled(Button)`
	padding:0px;
`;


function Profile() {
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	async function log() {
		await logout();
		window.location.href = `${process.env.API_URL}/login?redirect=${process.env.APP_URL}`;
	}

	return (<Fragment>
		<ProfileDefails>
			<List.Item
				prefix={
					<Image
						src={`${process.env.API_URL}${userSelf.img}`}
						style={{ borderRadius: 30 }}
						fit='cover'
						width={60}
						height={60}
					/>
				}
				description={userSelf.email}
			>
				{userSelf.name}{userSelf.role_title ? `(${userSelf.role_title})` : ''}
			</List.Item>
		</ProfileDefails>
		<List header="Active organization">
			<List.Item>
				{userSelf.active_organization.name}
			</List.Item>
		</List>
		<List header=" ">
			<List.Item>
				<Logout onClick={log} color='danger' block fill='none'>Log out</Logout>
			</List.Item>
		</List>
	</Fragment>);
}

export default Profile;
