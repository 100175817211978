import React, { createContext, useReducer } from 'react';

export const SET_USER_SELF = 'set_user_self';

const initialState = {
	userSelf: {}
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((existingState, action) => {
		switch (action.type) {
		case SET_USER_SELF:
			return { ...existingState, ...{ userSelf: action.userSelf } };
		default:
			throw new Error();
		}
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
