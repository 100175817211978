import React, { useContext, Fragment, useState, useRef, useEffect } from 'react';
import { List, SpinLoading, ErrorBlock, DotLoading, Popup, NavBar } from 'antd-mobile';
import { ExclamationCircleOutline, CloseOutline, DownlandOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { store } from '../utils/store';

const Files = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

const PreviewWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: auto;
	align-items: center;
	justify-content: center;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const getMb = bytes => (bytes / (1024 * 1024)).toFixed(2);

function FilesList(props) {
	const {
		files,
		relatedObjectType,
		loading
	} = props;
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const [showPopup, setShowPopup] = useState(false);
	const [selectedItem, setSelectedItem] = useState({});
	const [loadingPreview, setLoadingPreview] = useState(true);
	const iFrameRef = useRef(null);

	function onDownload(file) {
		const url = file.id ? `${process.env.API_URL}/api/file/${file.id}/download` : null;
		if (url) {
			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.target = '_blank';
			anchor.download = file.name;
			anchor.click();
		}
	}

	const onPreview = (file) => {
		const isImage = file.type.toString().includes('image');
		const isPdf = file.type.toString().includes('pdf');

		if (isImage || isPdf) {
			setSelectedItem(file);
			setShowPopup(true);
		} else {
			onDownload(file);
		}
	};

	useEffect(() => {
		setLoadingPreview(showPopup);
		if (!showPopup) {
			setSelectedItem({});
		}
	}, [showPopup]);

	useEffect(() => {
		if (iFrameRef.current) {
			iFrameRef.current.addEventListener('load', () => setLoadingPreview(false));
		}
		return () => {
			if (iFrameRef.current) {
				iFrameRef.current.removeEventListener('load', () => setLoadingPreview(false));
			}
		};
	}, [iFrameRef.current]);

	function getPreviewContent() {
		const url = selectedItem.id ? `${process.env.API_URL}/api/file/${selectedItem.id}/download` : null;

		if (url) {
			const isImage = selectedItem.type.toString().includes('image');
			const isPdf = selectedItem.type.toString().includes('pdf');

			return <PreviewWrapper>
				{isImage ? <img onLoad={() => setLoadingPreview(false)} style={{ width: '100%' }} src={url}/> : null}
				{isPdf ? <iframe ref={iFrameRef} style={{ width: '100%', border: 'none', height: '100%' }} type="application/pdf"
					src={url} id="pdf_content"><p>Not found...'</p>
				</iframe> : null}
			</PreviewWrapper>;
		}

		return null;
	}

	function getFilesList() {
		if (!files.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				description=""
				title={'No files found'}
				status='empty'
			/>;
		}

		function getExtra(file) {
			if (file.uploading) {
				return <DotLoading />;
			}

			if (file.error) {
				return <ExclamationCircleOutline fontSize={24} color='var(--adm-color-danger)' />;
			}

			return `${getMb(file.size)}Mb`;
		}

		return <Files>
			{files.map((file) => {
				return <List.Item
					key={file.id}
					title={(relatedObjectType && file.object_type === relatedObjectType) ? 'Instructions/Manual' : ''}
					extra={getExtra(file)}
					onClick={() => {
						onPreview(file);
					}}
					clickable={!file.error && !file.uploading}
				>
					{file.name || '-'}
				</List.Item>;
			})}
		</Files>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : <Fragment>
			{getFilesList()}
			<Popup
				position='right'
				visible={showPopup}
				destroyOnClose={true}
				bodyStyle={{ width: '100%' }}
				onClose={() => setShowPopup(false)}
			>
				<Nav
					backArrow={<CloseOutline />}
					onBack={() => setShowPopup(false)}
					right={loadingPreview ? <DotLoading /> : <DownlandOutline onClick={() => onDownload(selectedItem)} fontSize={'24px'} />}
					back={''}>
					{selectedItem.name}
				</Nav>
				{getPreviewContent(selectedItem)}
			</Popup>
		</Fragment>
	);
}

export default FilesList;
