/* eslint-disable no-nested-ternary */
import React, { useContext, Fragment } from 'react';
import { NavBar, Tabs, List, Popup, Ellipsis, Badge, Switch } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import moment from 'moment';
import styled from 'styled-components';
import { store } from '../utils/store';
import Signatures from './Signatures';
import { updateWorkOrderOperationTask } from '../api';
import InputComponent from './Input';
import Files from '../containers/Files';
import Notes from '../containers/Notes';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';

const TaskWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow: auto;
	width: 100%;
	background: #f0f2f5;
`;

const Overview = styled.div`
	padding: 12px 0px;
`;

const TaskInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	.adm-list-item-title {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const Description = styled(List.Item)`
	font-size: var(--adm-font-size-9);
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow: auto;
		padding: 0;
	}
`;

function SubTaskPopup(props) {
	const {
		subTask, visible, onClose, onChange
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;

	async function updateSubTask(data) {
		const payload = data;
		payload.id = subTask.id;

		await updateWorkOrderOperationTask(subTask.work_order_op_id, data);

		onChange(subTask.id);
	}

	async function onNotApplicableChange(value) {
		await updateSubTask({
			id: subTask.id,
			not_applicable: !value
		});
	}

	return (
		<Popup
			position='right'
			visible={visible}
			bodyStyle={{ width: '100%' }}
			onClose={onClose}
		>
			<TaskWrapper>
				<Nav backArrow={<CloseOutline />} onBack={onClose} back={''}>
					Subtask
				</Nav>
				<TaskInfo>
					<Description><Ellipsis
						direction='end'
						rows={4} content={subTask.description || '-'}
						expandText="Show"
						collapseText="Hide"
					/></Description>
				</TaskInfo>
				<ContentWrapper>
					<TabBar defaultActiveKey="overview">
						<Tabs.Tab
							title={'Overview'}
							key='overview'
						>
							<Overview>
								{subTask.is_input ?
									<InputComponent
										placeholder="Insert value"
										onSave={updateSubTask}
										dataKey="input_value"
										disabled={!matchesPermission(userSelf, 'edit_work_order_operations')}
										title={subTask.description}
										initialValue={subTask.input_value}
									/> :
									<Fragment>
										{subTask.is_optional && <List header={'Applicable'}>
											<List.Item
											>
												<Switch checked={!subTask.not_applicable} onChange={onNotApplicableChange} />
											</List.Item>
										</List>}
										{!subTask.not_applicable ? <InputComponent
											onSave={updateSubTask}
											disabled={!matchesPermission(userSelf, 'edit_work_order_operation')}
											dataKey="done_date"
											title="Done date"
											isRequired={true}
											type="date"
											initialValue={subTask.done_date}
										/> :
											<List header={'Done date'}>
												<List.Item>
													N/A
												</List.Item>
											</List>}
										{featureEnabled(userSelf, 'work_order_staff_enabled') ? (!subTask.not_applicable ? <Signatures
											onChange={(id, removed) => {
												if (!removed) {
													return updateSubTask({
														done_date: moment()
													});
												}

												return onChange(subTask.id);
											}}
											item={subTask}
											objectType={'operation_task'}
										/> : <List header={'Signatures'}>
											<List.Item>
												N/A
											</List.Item>
										</List>) : null}
									</Fragment>}
							</Overview>
						</Tabs.Tab>
						<Tabs.Tab
							title={<Badge
								content={subTask.files_count || null}
								style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
									Files
							</Badge>}
							key='files'
						>
							<Files
								onChange={() => onChange(subTask.id)}
								objectType="work_order_op_task"
								relatedObjectType="operation_task"
								objectId={subTask.id}
								disabled={!matchesPermission(userSelf, 'manage_files_work_order_operations')}
								relatedObjectId={subTask.operation_task_id}
							/>
						</Tabs.Tab>
						<Tabs.Tab
							title={<Badge
								content={subTask.notes_count || null}
								style={{ '--color': 'var(--adm-color-primary)', '--right': '-10px', '--top': '4px' }}>
									Notes
							</Badge>}
							key='notes'
						>
							<Notes
								onChange={() => onChange(subTask.id)}
								objectType="work_order_op_task"
								relatedObjectType="operation_task"
								objectId={subTask.id}
								disabled={!matchesPermission(userSelf, 'manage_files_work_order_operations')}
								relatedObjectId={subTask.operation_task_id}
							/>
						</Tabs.Tab>
					</TabBar>
				</ContentWrapper>
			</TaskWrapper>
		</Popup>
	);
}

export default SubTaskPopup;
