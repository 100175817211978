import React from 'react';
import enUS from 'antd-mobile/es/locales/en-US';

import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd-mobile';
import App from './containers/App';
import { StateProvider } from './utils/store';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ConfigProvider locale={enUS}><StateProvider><App /></StateProvider></ConfigProvider>);
