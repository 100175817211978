import React, { useState, useEffect } from 'react';
import { CloseOutline } from 'antd-mobile-icons';
import { Popup, NavBar, TextArea, Button } from 'antd-mobile';
import styled from 'styled-components';

const NoteContent = styled.div`
	background: #fff;
	margin-top: 12px;
	padding: 12px;
`;

const Save = styled(Button)`
	font-size: 17px;
`;

const ButtonWrapper = styled.div`
	margin-top: 24px;
	padding: 12px;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

function AddNotePopup(props) {
	const {
		note,
		visible,
		onClose,
		saving,
		onDelete,
		onSave
	} = props;
	const [value, setValue] = useState(note.content);

	useEffect(() => {
		setValue(note.content || null);
	}, [note]);


	async function onSaveClick() {
		const saveValue = value;


		const data = {
			id: note.id,
			content: saveValue
		};

		onSave(data);
	}

	return (
		<Popup
			destroyOnClose
			position='right'
			visible={visible}
			bodyStyle={{ width: '100%', background: '#f0f2f5' }}
			onClose={onClose}
		>
			<Nav
				backArrow={<CloseOutline />}
				onBack={onClose}
				back={''}
				right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
			>
				{note.id ? 'Edit note' : 'Add note'}
			</Nav>
			<NoteContent>
				<TextArea onChange={val => setValue(val)} value={value || ''} placeholder="Note content" rows={5} />
			</NoteContent>
			{note.id && <ButtonWrapper>
				<Button block saving={saving} onClick={() => onDelete(note.id)} color="danger" fill="outline">Delete</Button>
			</ButtonWrapper>}
		</Popup>
	);
}

export default AddNotePopup;
