import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { getWorkOrderDefects } from '../api';
import { store } from '../utils/store';
import DefectsList from '../components/DefectsList';

const DefectsWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function Defects(props) {
	const { workOrderId, stats } = props;
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(true);
	const [err, setErr] = useState(false);
	const [defects, setDefects] = useState([]);

	async function fetchDefects() {
		setLoading(true);
		const query = `?work_order_id=${workOrderId}`;

		try {
			const data = await getWorkOrderDefects(workOrderId, query);
			setDefects(data.items);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (workOrderId) {
			fetchDefects();
		}
	}, [workOrderId]);

	return (
		<DefectsWrapper>
			<ListWrapper>
				<DefectsList
					stats={stats}
					loading={loading}
					defects={defects}
					workOrderId={workOrderId}
				/>
			</ListWrapper>
		</DefectsWrapper>
	);
}

export default Defects;
