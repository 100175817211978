import React, { useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { List, Ellipsis, SpinLoading, Tag, ErrorBlock } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import { getWorkOrderStatusData } from '../utils/helpers';

const WorkOrders = styled(List)`
	width: 100%;
`;

const Status = styled(Tag)`
`;

const Aircraft = styled.span`
	margin-right: 8px;
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function WorkOrdersList(props) {
	const {
		workOrders,
		loading
	} = props;
	const globalState = useContext(store);
	const history = useHistory();
	const { userSelf } = globalState;

	function getList() {
		if (!workOrders.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title="No work orders found"
				description="Try new search?"
				status='empty'
			/>;
		}

		return <WorkOrders>
			{workOrders.map(wo => (
				<List.Item
					key={wo.id}
					title={<Fragment>
						<Aircraft>{wo.aircraft_registration || wo.item_description}</Aircraft>
						<Status color={getWorkOrderStatusData(wo.status).color}>
							{getWorkOrderStatusData(wo.status).text}
						</Status>
					</Fragment>}
					description={<Ellipsis direction='end' rows={3} content={wo.description || '-'} />}
					onClick={() => history.push(`/work-order/${wo.id}`)}
					clickable
				>
					{wo.nr || '-'}
				</List.Item>
			))}
		</WorkOrders>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : getList()
	);
}

export default WorkOrdersList;
