import React, { useState, useContext, useEffect } from 'react';
import { InfiniteScroll, NavBar, SearchBar } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { ScanCodeOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { getWorkOrders } from '../api';
import { store } from '../utils/store';
import WorkOrdersList from '../components/WorkOrdersList';
import ScanPopup from '../components/ScanPopup';

const WorkOrdersWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	padding: 12px 0px;
	background: #f0f2f5;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
	.adm-nav-bar-left,
	.adm-nav-bar-right {
		flex: 0;
	}
	.adm-nav-bar-title {
		padding: 0px 12px 0px 0px;
	}
`;

function WorkOrders() {
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [showScanPopup, setShowScanPopup] = useState(false);
	const [err, setErr] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [workOrders, setWorkOrders] = useState([]);
	const [hasMore, setHasMore] = useState('');
	const [page, setPage] = useState(1);

	async function fetchWorkOrders(currentPage, silent) {
		if (!silent) {
			setLoading(true);
		}
		try {
			const data = await getWorkOrders(`?page=${currentPage}&open=true`);
			const newList = [...workOrders, ...data.items];
			setHasMore(newList.length < data.total_count);
			setWorkOrders(newList);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchWorkOrders(page);
	}, []);

	function navigate(value) {
		setShowScanPopup(false);

		const values = value.split('_');
		const type = values[0];
		const workOrderId = values[1];
		const itemId = values[2];

		if (type && (workOrderId || itemId)) {
			switch (type) {
			case 'WO':
				return history.push(`/work-order/${workOrderId}`);
			case 'WOP':
				return history.push(`/work-order/${workOrderId}/task/${itemId}`);
			case 'AW':
				return history.push(`/work-order/${workOrderId}/defect/${itemId}`);
			default:
				break;
			}
		}

		return null;
	}

	async function onLoadMore() {
		const newPage = page + 1;
		setPage(newPage);
		await fetchWorkOrders(newPage, true);
	}

	return (
		<WorkOrdersWrapper>
			<Nav back={null} right={<ScanCodeOutline onClick={() => setShowScanPopup(true)} fontSize={24} shape="rectangular" />}>
				<SearchBar value={searchValue} onChange={setSearchValue} placeholder='Search' />
			</Nav>
			<ListWrapper>
				<WorkOrdersList
					loading={loading}
					workOrders={workOrders.filter(wo => wo.nr.includes(searchValue.toLowerCase()))}
				/>
				<InfiniteScroll loadMore={onLoadMore} hasMore={hasMore} />
			</ListWrapper>
			<ScanPopup onClose={() => setShowScanPopup(false)} onScan={navigate} visible={showScanPopup} />
		</WorkOrdersWrapper>
	);
}

export default WorkOrders;
