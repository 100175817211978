import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { getWorkOrderOperations } from '../api';
import { store } from '../utils/store';
import TasksList from '../components/TasksList';

const TasksWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

function Tasks(props) {
	const { workOrderId, stats } = props;
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(true);
	const [err, setErr] = useState(false);
	const [tasks, setTasks] = useState([]);

	async function fetchTasks() {
		setLoading(true);
		try {
			const query = `?work_order_id=${workOrderId}`;
			const data = await getWorkOrderOperations(workOrderId, query);
			setTasks(data.items);
		} catch (e) {
			setErr(true);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (workOrderId) {
			fetchTasks();
		}
	}, [workOrderId]);

	return (
		<TasksWrapper>
			<ListWrapper>
				<TasksList
					stats={stats}
					loading={loading}
					tasks={tasks}
					workOrderId={workOrderId}
				/>
			</ListWrapper>
		</TasksWrapper>
	);
}

export default Tasks;
