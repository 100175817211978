import React, { useState, useContext, useEffect } from 'react';
import { FloatingBubble, Toast, Dialog } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { createOrEditNote, deleteNote, getNotes } from '../api';
import { store } from '../utils/store';
import NotesList from '../components/NotesList';
import AddNotePopup from '../components/AddNotePopup';

const NotesWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

const getQuery = ({
	objectId, objectType, relatedObjectId, relatedObjectType
}) => `?object_id=${objectId}&object_type=${objectType}
		${relatedObjectType && relatedObjectId ? `&related_object_id=${relatedObjectId}&related_object_type=${relatedObjectType}` : ''}`;

function Notes(props) {
	const {
		onChange,
		objectType,
		objectId,
		disabled,
		relatedObjectType,
		relatedObjectId
	} = props;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [err, setErr] = useState(false);
	const [notes, setNotes] = useState([]);
	const [selectedNote, setSelectedNote] = useState({});
	const [showPopover, setShowPopover] = useState(false);

	async function fetchNotes(silent) {
		const query = getQuery({
			objectId,
			objectType,
			relatedObjectId,
			relatedObjectType
		});

		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getNotes(query);
			setNotes(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (objectId) {
			fetchNotes();
		}
	}, [objectId, objectType, relatedObjectId, relatedObjectType]);

	function closePopover() {
		setShowPopover(false);
		setSelectedNote({});
	}

	async function onDeleteNote(id) {
		const result = await Dialog.confirm({
			cancelText: 'Cancel',
			confirmText: 'Yes',
			style: { textAlign: 'center' },
			content: 'Delete this note?'
		});

		if (result) {
			setSaving(true);
			await deleteNote(id).catch(() => {
				Toast.show({
					icon: 'fail',
					content: <ToastContent>
						Something went wrong, cannot delete note.
					</ToastContent>
				});
			});

			setSaving(false);
			closePopover();
			fetchNotes(true);
		}
	}

	async function saveNote(data) {
		setSaving(true);

		const payload = {
			...data,
			...{
				object_id: objectId,
				object_type: objectType
			}
		};


		await createOrEditNote(payload).catch(() => {
			Toast.show({
				icon: 'fail',
				content: <ToastContent>
					Something went wrong, cannot save note.
				</ToastContent>
			});
		});

		setSaving(false);
		closePopover();
		fetchNotes(true);
		if (onChange) {
			onChange();
		}
	}

	return (
		<NotesWrapper>
			{!disabled && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => setShowPopover(true)}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<ListWrapper>
				<NotesList
					disabled={disabled}
					onClick={(note) => {
						setSelectedNote(note);
						setShowPopover(true);
					}}
					relatedObjectType={relatedObjectType}
					notes={notes}
					loading={loading}
				/>
			</ListWrapper>
			<AddNotePopup
				onDelete={onDeleteNote}
				visible={showPopover}
				note={selectedNote}
				saving={saving}
				onClose={closePopover}
				onSave={saveNote}
			/>
		</NotesWrapper>
	);
}

export default Notes;
