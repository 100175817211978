import React, { useState, useContext, useEffect } from 'react';
import { FloatingBubble } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import styled from 'styled-components';
import { getFiles, uploadFile } from '../api';
import { store } from '../utils/store';
import FilesList from '../components/FilesList';

const FilesWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ListWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const getQuery = ({
	objectId, objectType, relatedObjectId, relatedObjectType
}) => `?object_id=${objectId}&object_type=${objectType}
		${relatedObjectType && relatedObjectId ? `&related_object_id=${relatedObjectId}&related_object_type=${relatedObjectType}` : ''}`;

function Files(props) {
	const {
		onChange,
		disabled,
		objectType,
		objectId,
		relatedObjectType,
		relatedObjectId
	} = props;
	const globalState = useContext(store);
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [files, setFiles] = useState([]);

	const hiddenFileInput = React.useRef(null);

	async function fetchFiles(silent) {
		const query = getQuery({
			objectId,
			objectType,
			relatedObjectId,
			relatedObjectType
		});
		if (!silent) {
			setLoading(true);
		}

		try {
			const data = await getFiles(query);
			setFiles(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		if (objectId) {
			fetchFiles();
		}
	}, [objectId, objectType, relatedObjectId, relatedObjectType]);

	async function handleUpload(ev) {
		ev.preventDefault();
		const file = ev.target.files[0];
		const query = getQuery({
			objectId,
			objectType
		});
		const formData = new FormData();
		formData.append('file', file);
		formData.append('fileName', file.name);

		const tempId = Date.now();

		const newFile = {
			name: file.name,
			size: file.size,
			object_type: objectType,
			object_id: objectId,
			uploading: true,
			id: tempId
		};

		setFiles([...[newFile], ...files]);

		const uploaded = await uploadFile(query, formData).catch(() => {
			const errorFile = {
				name: file.name,
				size: file.size,
				error: true,
				id: tempId
			};
			setFiles([...[errorFile], ...files]);
		});

		if (uploaded) {
			setFiles([...uploaded, ...files]);
			if (onChange) {
				onChange();
			}
		}
	}


	return (
		<FilesWrapper>
			{!disabled && <FloatingBubble
				style={{
					'--initial-position-bottom': '74px',
					'--initial-position-right': 'calc(50% - 24px)',
					'--edge-distance': '24px'
				}}
				onClick={() => {
					hiddenFileInput.current.click();
				}}
			>
				<AddOutline fontSize={32} />
			</FloatingBubble>}
			<input
				type="file"
				ref={hiddenFileInput}
				onChange={handleUpload}
				style={{ display: 'none' }}
			/>
			<ListWrapper>
				<FilesList
					disabled
					relatedObjectType={relatedObjectType}
					files={files}
					loading={loading}
				/>
			</ListWrapper>
		</FilesWrapper>
	);
}

export default Files;
