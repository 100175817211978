import React, { useState, useContext, useEffect, Fragment } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import { CloseOutline } from 'antd-mobile-icons';
import { List, Popup, NavBar, Calendar, TextArea, Button, Toast, Input } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';
import { SQL_DATE_FORMAT } from '../utils/constants';

const InputContent = styled.div`
	background: #fff;
	margin-top: 12px;
	padding: 12px;
`;

const Save = styled(Button)`
	font-size: 17px;
`;

const Required = styled.span`
	display: inline-block;
	margin-left: 2px;
	color: #f5222d;
	font-size: 14px;
	line-height: 1;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const ToastContent = styled.div`
	word-break: normal;
	text-align: center;
	width: 100%;
`;

function InputComponent(props) {
	const {
		title,
		dataKey,
		initialValue,
		type,
		isRequired,
		placeholder,
		onSave,
		disabled
	} = props;
	const globalState = useContext(store).state;
	const [saving, setSaving] = useState(false);
	const [value, setValue] = useState(initialValue);
	const [visible, setVisible] = useState(false);
	const { userSelf } = globalState;

	function onClose() {
		setVisible(false);
	}

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue, visible]);

	function getInputContent() {
		switch (type) {
		case 'date':
			return <Calendar
				selectionMode='single'
				defaultValue={new Date()}
				value={value}
				onChange={val => setValue(val)}
			/>;
		case 'text':
			return <TextArea onChange={val => setValue(val)} value={value || ''} placeholder={placeholder} rows={5} />;
		default:
			return <Input
				placeholder={placeholder}
				value={value || ''}
				onChange={val => setValue(val)}
			/>;
		}
	}

	async function onSaveClick() {
		setSaving(true);
		let saveValue = value;

		switch (type) {
		case 'date':
			saveValue = moment(value).format(SQL_DATE_FORMAT);
			break;
		default:
			break;
		}

		const data = {
			[dataKey]: saveValue
		};

		await onSave(data).catch(() => Toast.show({
			icon: 'fail',
			content: <ToastContent>
				Something went wrong, cannot save.
			</ToastContent>
		}));

		setSaving(false);
		setVisible(false);
	}

	function getInitialValue() {
		switch (type) {
		case 'date':
			return initialValue ? moment(initialValue).format(get(userSelf.settings, 'date_format')) : '-';
		case 'text':
			return initialValue || '-';
		default:
			return initialValue || '-';
		}
	}

	return (
		<Fragment>
			<List header={<Fragment>
				{title}
				{isRequired ? <Required>*</Required> : null}
			</Fragment>}>
				<List.Item
					onClick={() => (disabled ? null : setVisible(true))}
					clickable={!disabled}
				>
					{getInitialValue()}
				</List.Item>
			</List>
			<Popup
				destroyOnClose
				position='right'
				visible={visible}
				bodyStyle={{ width: '100%', background: '#f0f2f5' }}
				onClose={onClose}
			>
				<Nav
					backArrow={<CloseOutline />}
					onBack={onClose}
					back={''}
					right={<Save fill='none' loading={saving} onClick={onSaveClick}>Save</Save>}
				>
					{title}
				</Nav>
				<InputContent>
					{getInputContent()}
				</InputContent>
			</Popup>
		</Fragment>
	);
}

export default InputComponent;
