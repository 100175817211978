import React, { useState, useContext, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar, Tabs, List, Tag, Ellipsis, Badge, Skeleton, ErrorBlock } from 'antd-mobile';
import styled from 'styled-components';
import { getWorkOrder, getWorkOrderStats } from '../api';
import { store } from '../utils/store';
import { getWorkOrderStatusData } from '../utils/helpers';
import Tasks from './Tasks';
import Defects from './Defects';
import Materials from './Materials';
import Files from './Files';
import Notes from './Notes';
import { featureEnabled, matchesPermission } from '../utils/permission-helpers';
import Tools from './Tools';

const WorkOrdersWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;
const ContentWrapper = styled.div`
	flex: 1;
	min-height: 0;
	overflow:  auto;
	width: 100%;
	background: #f0f2f5;
`;

const Status = styled(Tag)`
	margin-left: 8px;
`;

const WorkOrderInfo = styled.div`
	padding: 0px 12px;
	background: #fff;

	.adm-list-item-title {
		margin-bottom: 12px;
		display: flex;
		align-items: center;
	}

	h2 {
		margin: 0px 8px 0px 0px;
		display: inline-block;
		color: var(--adm-color-text);
	}

	.adm-ellipsis {
		a {
			margin-left: 8px;
		}
	}
`;

const NotFound = styled(ErrorBlock)`
	height: 100%;
	padding: 12px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

const TabBar = styled(Tabs)`
	display: flex;
	flex-direction: column;
	height: 100%;
	.adm-tabs-header {
		background: #fff;
	}

	.adm-tabs-content {
		flex: 1;
		overflow:  auto;
		padding: 0px;
	}
`;

function WorkOrder() {
	const params = useParams();
	const history = useHistory();
	const { id } = params;
	const globalState = useContext(store).state;
	const { userSelf } = globalState;
	const [loading, setLoading] = useState(false);
	const [err, setErr] = useState(false);
	const [workOrder, setWorkOrder] = useState({});
	const [stats, setStats] = useState([]);

	async function fetchWorkOrder() {
		setLoading(true);
		try {
			const data = await getWorkOrder(id);
			setWorkOrder(data);
		} catch (e) {
			setErr(true);
		}
		setLoading(false);
	}

	async function fetchWorkOrdeStats() {
		setLoading(true);
		try {
			const data = await getWorkOrderStats(id);
			setStats(data);
		} catch (e) {
			setErr(true);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchWorkOrder();
		fetchWorkOrdeStats();
	}, []);

	function getUndoneDefects() {
		const allTasks = stats.filter(s => s.type === 'defect');
		const doneTasks = allTasks.filter(s => s.all_done).length;

		return allTasks.length - doneTasks;
	}

	function getUndoneOps() {
		const allTasks = stats.filter(s => s.type === 'work_order_op');
		const doneTasks = allTasks.filter(s => s.all_done).length;

		return allTasks.length - doneTasks;
	}

	return (
		err ? <NotFound
			title="Work order not found"
			description="This work order does not exist, or you dont have rights to view it"
			status='empty'
		/> : <WorkOrdersWrapper>
			<Nav onBack={() => history.push('/')} back={''}>
				Work Order
			</Nav>
			<WorkOrderInfo>
				{loading ?
					<Fragment>
						<Skeleton.Title animated/>
						<Skeleton.Paragraph lineCount={1} animated/>
					</Fragment> :
					<List.Item
						key={workOrder.id}
						title={<Fragment>
							<h2>{workOrder.nr || '-'}</h2>
							{workOrder.aircraft_registration || workOrder.item_description}
							<Status color={getWorkOrderStatusData(workOrder.status).color}>
								{getWorkOrderStatusData(workOrder.status).text}
							</Status>
						</Fragment>}
						description={<Ellipsis
							direction='end'
							rows={2} content={workOrder.description || '-'}
							expandText="Show"
							collapseText="Hide"
						/>}
					>
					</List.Item>}
			</WorkOrderInfo>
			<ContentWrapper>
				<TabBar defaultActiveKey="tasks">
					<Tabs.Tab
						title={<Badge content={getUndoneOps() || null} style={{ '--right': '-10px', '--top': '4px' }}>Tasks</Badge>}
						key='tasks'
					>
						<Tasks stats={stats} workOrderId={workOrder.id} />
					</Tabs.Tab>
					<Tabs.Tab title={<Badge content={getUndoneDefects() || null} style={{ '--right': '-10px', '--top': '4px' }}>Additional works</Badge>} key='defects'>
						<Defects stats={stats} workOrderId={workOrder.id} />
					</Tabs.Tab>
					{featureEnabled(userSelf, 'warehouse_enabled') && matchesPermission(userSelf, 'view_work_order_materials') &&
						<Tabs.Tab title='Materials' key='materials'>
							<Materials workOrderId={workOrder.id} />
						</Tabs.Tab>
					}
					{featureEnabled(userSelf, 'tools_enabled') && matchesPermission(userSelf, 'view_work_order_tools') &&
						<Tabs.Tab title='Tools' key='tools'>
							<Tools
								workOrderId={workOrder.id}
							/>
						</Tabs.Tab>
					}
					<Tabs.Tab title='Files' key='files'>
						<Files
							objectType="work_order"
							objectId={workOrder.id}
							disabled={!matchesPermission(userSelf, 'manage_files_notes_work_orders')}
						/>
					</Tabs.Tab>
					<Tabs.Tab title='Notes' key='notes'>
						<Notes
							objectType="work_order"
							objectId={workOrder.id}
							disabled={!matchesPermission(userSelf, 'manage_files_notes_work_orders')}
						/>
					</Tabs.Tab>
				</TabBar>
			</ContentWrapper>
		</WorkOrdersWrapper>
	);
}

export default WorkOrder;
