import React, { useContext, useEffect, useState } from 'react';
import { Popup, Button, List, Stepper, Ellipsis } from 'antd-mobile';
import styled from 'styled-components';
import { store } from '../utils/store';

const PopupContent = styled(List)`
	padding: 12px;
	margin-top: 12px;

	.adm-list-body {
		border-top: none;
	}
`;

function AddMaterialPopup(props) {
	const {
		selectedItem,
		visible,
		initialValue,
		onClose,
		onSave,
		loading,
		max,
		isAdd
	} = props;
	const [value, setValue] = useState(initialValue || 0);
	const globalState = useContext(store);
	const { userSelf } = globalState;

	function getUsedIn(material) {
		if (material.work_order_op_id) {
			return material.work_order_op_title || '-';
		}

		if (material.defect) {
			return material.defect_title || '-';
		}

		return null;
	}

	useEffect(() => {
		setValue(initialValue || 1);
	}, [selectedItem, visible, initialValue]);

	function onSaveClick() {
		onSave({
			id: selectedItem.id,
			amount: value
		});
	}

	return (
		<Popup
			visible={visible}
			showCloseButton={true}
			onClose={onClose}
			onMaskClick={onClose}
			bodyStyle={{
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
				minHeight: '20vh'
			}}
		>
			<PopupContent>
				<List.Item
					key={selectedItem.id}
					clickable={false}
					title={`${selectedItem.batch ? `${selectedItem.batch}` : ''} ${selectedItem.part_nr ? `/ ${selectedItem.part_nr}` : ''} ${selectedItem.serial ? `/ ${selectedItem.serial}` : ''}`}
					description={isAdd ?
						`${selectedItem.barcode ? `BC: ${selectedItem.barcode}` : ''}` :
						<Ellipsis direction='end' rows={1} content={`${getUsedIn(selectedItem) ? `Used in: ${getUsedIn(selectedItem)}` : ''}`} />
					}
					extra={<Stepper
						value={value}
						min={0}
						max={max}
						onChange={val => setValue(val)}
					/>}
				>
					{selectedItem.name || '-'}
				</List.Item>
				<Button
					style={{ marginTop: 12 }}
					onClick={onSaveClick}
					color="success"
					loading={loading}
					block
				>
					{isAdd ? 'Add' : 'Save'}
				</Button>
			</PopupContent>
		</Popup>
	);
}

export default AddMaterialPopup;
