import React, { useContext } from 'react';
import { List, Ellipsis, SpinLoading, ProgressCircle, ErrorBlock } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { store } from '../utils/store';

const Tasks = styled(List)`
	width: 100%;
	padding: 12px 0px;
	.adm-list-item-content-extra {
		display: flex;
	}
`;

const Progress = styled(ProgressCircle)`
	font-size: 12px;
`;

const SpinWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

function TasksList(props) {
	const {
		tasks,
		loading,
		stats,
		workOrderId
	} = props;
	const globalState = useContext(store);
	const history = useHistory();
	const { userSelf } = globalState;

	function getList() {
		if (!tasks.length) {
			return <ErrorBlock
				style={{ paddingTop: 12 }}
				title="No tasks found"
				description=""
				status='empty'
			/>;
		}

		return <Tasks>
			{tasks.map((task) => {
				const itemStats = stats.find(s => s.id === task.id);
				let progress = <Progress percent={0}>N/A</Progress>;

				if (itemStats) {
					const precent = (itemStats.done_items / itemStats.total_items) * 100;
					progress = <Progress
						percent={precent}
						style={{
							'--fill-color': itemStats.all_done ? 'var(--adm-color-success)' : 'var(--adm-color-primary)'
						}}
					>
						{itemStats.all_done ? 'DONE' : `${itemStats.done_items}/${itemStats.total_items}`}
					</Progress>;
				}

				return <List.Item
					extra={progress}
					key={task.id}
					description={<Ellipsis direction='end' rows={3} content={task.description || '-'} />}
					onClick={() => history.push(`/work-order/${workOrderId}/task/${task.id}`)}
					clickable
				>
					{task.name || '-'}
				</List.Item>;
			})}
		</Tasks>;
	}

	return (
		loading ? <SpinWrapper><SpinLoading style={{ '--size': '48px' }} /></SpinWrapper> : getList()
	);
}

export default TasksList;
