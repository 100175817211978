import React, { useEffect, useRef, useState } from 'react';
import { DotLoading, NavBar, Popup } from 'antd-mobile';
import { Html5Qrcode } from 'html5-qrcode';
import { CloseOutline } from 'antd-mobile-icons';
import styled from 'styled-components';

const ScanWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
`;

const ContentWrapper = styled.div`
	align-items: center;
	justify-content: center;
	height: 100%;
	display: flex;
	background: #f0f2f5;
`;

const ReaderWrapper = styled.div`
	width: 100%;
`;

const Nav = styled(NavBar)`
	background: #fff;
	display: flex;
	border-bottom: solid 1px var(--adm-color-border);
`;

function ScanPopup(props) {
	const {
		visible,
		onClose,
		onScan
	} = props;
	const readerID = useRef(Date.now().toString(32));
	const [loading, setLoading] = useState(false);
	const html5QrcodeScanner = useRef();

	function onScanSuccess(decodedText, decodedResult) {
		onScan(decodedText, decodedResult);
	}

	function onScanFailure(err) {
	}


	async function startCamera() {
		setLoading(true);
		html5QrcodeScanner.current = new Html5Qrcode(/* element id */ readerID.current);

		try {
			await html5QrcodeScanner.current.start(
				{ facingMode: 'environment' },
				{
					fps: 10,
					qrbox: { width: 200, height: 150 }
				},
				onScanSuccess,
				onScanFailure
			);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	}


	useEffect(() => {
		if (visible) {
			startCamera();
		}

		return () => {
			if (html5QrcodeScanner.current && html5QrcodeScanner.current.getState() === 2) {
				html5QrcodeScanner.current.stop();
			}
		};
	}, [visible]);

	return (
		<Popup
			style={{ zIndex: 1001 }}
			position='right'
			visible={visible}
			forceRender
			destroyOnClose={true}
			bodyStyle={{ width: '100vw' }}
			onClose={onClose}
		>
			<ScanWrapper>
				<Nav backArrow={<CloseOutline />} right={loading && <DotLoading />} onBack={onClose} back={''}>
					Scan Barcode / QRCode
				</Nav>
				<ContentWrapper>
					<ReaderWrapper id={readerID.current} ></ReaderWrapper>
				</ContentWrapper>
			</ScanWrapper>
		</Popup>
	);
}

export default ScanPopup;
